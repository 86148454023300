<template>
  <div>
    <v-radio-group class="mt-0" v-model="type" dense hide-details>
      <div class="d_flex align-center mb-3">
        <v-radio
          class="mb-0"
          :value="1"
          @change="$emit('change', specificValue)"
        ></v-radio>
        <v-select
          v-model="specificValue"
          append-icon="mdi-menu-swap"
          class="text-body-1 mt-0"
          dense
          hide-details
          :items="items"
          :menu-props="{ nudgeTop: '-27px' }"
          item-text="text"
          item-value="value"
          placeholder="Chọn giá trị"
          @change="updateSpecificValue($event)"
        ></v-select>
      </div>
      <div class="d_flex align-center">
        <v-radio
          class="mb-0"
          :value="2"
          @change="$emit('change', customValue)"
        ></v-radio>
        <tp-input-range-price
          :value="customValue"
          @change="updateCustomValue($event)"
        ></tp-input-range-price>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    disabledNegativeNumber: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => {
        return [
          {
            id: 1,
            name: "Tất cả",
            value: { from: 0, to: 0 }
          }
        ];
      }
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      customValue: {
        from: null,
        to: null
      },
      specificValue: null,
      type: 1
    };
  },
  created() {
    if (this.value) {
      // Check value is specific value
      this.checkSpecificValue(this.value);
    }
  },
  methods: {
    checkSpecificValue(val) {
      let check = false;

      this.items.forEach(item => {
        if (item.value.from === val.from && item.value.to === val.to) {
          this.type = 1;
          this.specificValue = item.value;
          check = true;
          return false;
        }
      });

      if (!check) {
        this.type = 2;
        this.customValue = val;
      }
    },
    updateCustomValue(val) {
      this.customValue = val;
      this.type = 2;
      this.$emit("change", val);
    },
    updateSpecificValue(val) {
      this.type = 1;
      this.$emit("change", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-hover {
  cursor: pointer;
}
.input-date--customize {
  font-size: 13px !important;
}
</style>
